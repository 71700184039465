<template>
  <div>
    <CRow>
        <CCol lg="12" style="margin-bottom:10px">
            <CButton type="submit" size="sm" class="float-left btn-primary" @click="goToList()" ><CIcon name="cil-list"/> 목록</CButton>
            <form @submit.prevent="formSubmit" method="post">
                <CButton type="submit" size="sm" color="primary" class="float-right" :disabled="afterClick"><CIcon name="cil-pencil"/> 저장</CButton>
            </form>
        </CCol>
    </CRow>
    <CRow>
        <CCol lg="5">
            <CCard>
                <CCardHeader>
                    <strong> 계정 정보 </strong>
                </CCardHeader>
                <CCardBody>
                    <CForm>
                        <CInput
                            label="아이디(*)"
                            type="text"
                            :value.sync="$v.adminInfo.adminId.$model"
                            :isValid="!$v.adminInfo.adminId.$error"
                            invalidFeedback="필수 입력입니다. (아이디는 영문(대/소문자) 구분하지 않습니다.)"
                            horizontal
                        />
                        <CInput
                            label="이름(*)"
                            type="text"
                            :value.sync="$v.adminInfo.adminNm.$model"
                            :isValid="!$v.adminInfo.adminNm.$error"
                            invalidFeedback="필수 입력입니다."
                            horizontal
                        />
                        <CInput
                            label="비밀번호(*)"
                            type="password"
                            v-model="adminInfo.pswd"
                            :value.sync="$v.adminInfo.pswd.$model"
                            :isValid="!$v.adminInfo.pswd.$error"
                            autocomplete="new-password"
                            invalidFeedback="비밀번호는 영문(대/소문자), 숫자, 특수문자를 3가지 이상 조합하여 9~20자리 이상 설정해야합니다."
                            horizontal
                        />
                        <CInput
                            label="비밀번호 확인(*)"
                            type="password"
                            v-model="adminInfo.confirmPassword"
                            :value.sync="$v.adminInfo.confirmPassword.$model"
                            :isValid="!$v.adminInfo.confirmPassword.$error"
                            autocomplete="new-password"
                            invalidFeedback="비밀번호가 일치하지 않습니다."
                            horizontal
                        />
                        <CInput
                            label="전화번호"
                            type="tel"
                            :lazy="false"
                            placeholder="ex) 01012345678"
                            :value.sync="$v.adminInfo.phoneNumber.$model"
                            :isValid="!$v.adminInfo.phoneNumber.$error"
                            invalidFeedback="유효한 전화번호를 입력해주세요."
                            horizontal
                        />

                        <CInput
                            label="Email"
                            type="email"
                            :lazy="false"
                            v-model="adminInfo.email"
                            :value.sync="$v.adminInfo.email.$model"
                            :isValid="!$v.adminInfo.email.$error"
                            placeholder="ex) raon@raonsecure.com"
                            invalidFeedback="유효한 이메일을 입력해주세요."
                            horizontal
                        />
                        <CRow form class="form-group mb-1" v-for="(v, index) in $v.adminInfo.ipInfoList.$each.$iter" :key="index">
                            <CCol sm="3" v-text="index == 0? 'IP' : ''"></CCol> 
                            <CInput
                                type="text"
                                class="ml-0"
                                :lazy="false"
                                :value.sync="v.startIp.$model"
                                :isValid="!v.startIp.$error"
                                invalidFeedback="유효한 IP를 입력해주세요."
                                placeholder="ex) 192.168.0.1"
                                horizontal
                            />       
                            <!-- IP 1개로 제한.  하기 주석 풀면 여러 개의 IP 사용가능-->                      
                            <!-- <div class="c-avatar" @click="addIpInput" v-if="index==adminInfo.ipInfoList.length-1">
                                <img
                                    src="@/assets/img/plus.png"
                                    class="c-avatar-img "
                                />
                            </div>
                            <div class="c-avatar" @click="removeIpInput" v-if="index!=0&&index==adminInfo.ipInfoList.length-1">
                                <img
                                    src="@/assets/img/minus.png"
                                    class="c-avatar-img "
                                />
                            </div>  -->
                        </CRow>
                        <CTextarea
                            label="Note"
                            placeholder=""
                            horizontal
                            rows="9"
                            :value.sync="$v.adminInfo.note.$model"
                            :isValid="!$v.adminInfo.note.$error"
                        />
                    </CForm>
                </CCardBody>
            </CCard>            
        </CCol>        
        <CCol lg="7">
            <PermissionGroupModal v-if="isOpenPermissionGroupModal" @close-modal="isOpenPermissionGroupModal=false" @add-item-event="addPermissionItem"/>
            <CCard>
                <CCardHeader>
                    <strong> 권한 그룹(*) </strong>
                    <CButton
                        type="submit"
                        size="sm"
                        variant="ghost"
                        class="float-right btn-primary btn-lft-margin"
                        @click="isOpenPermissionGroupModal=true"
                    >
                        <CIcon name="cil-plus"/>
                    </CButton>
                </CCardHeader>
                <CCardBody>
                    <CInput
                        horizontal
                        :value.sync="$v.adminInfo.groupNm.$model"
                        :isValid="!$v.adminInfo.groupNm.$error"
                        invalidFeedback="권한 그룹을 선택해주세요."
                        disabled=true
                    />
                </CCardBody>
            </CCard>
            <CCard v-if="adminInfo.groupNm">
                <CCardHeader>
                    <strong> 앱 제어 권한 </strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol lg="12" style="margin-bottom:3px">
                            <span v-if="permissionGroupInfo.allAppPermissionYn=='1'">모든 앱 허용</span>
                            <span v-if="permissionGroupInfo.allAppPermissionYn=='0'&&(!permissionGroupInfo.appPermissionInfoList||permissionGroupInfo.appPermissionInfoList.length ==0)">권한 없음</span>
                        </CCol>
                    </CRow>
                    <CDataTable
                        v-if="permissionGroupInfo.allAppPermissionYn=='0'&& permissionGroupInfo.appPermissionInfoList.length>0"
                        :hover="hover"
                        :striped="striped"
                        :border="border"
                        :small="small"
                        :fixed="fixed"
                        :items="permissionGroupInfo.appPermissionInfoList"
                        :fields="appFields"
                        :items-per-page="small ? 10 : 5"
                        pagination
                    >
                    <template #no="{index}">
                        <td class="py-2" >
                            {{permissionGroupInfo.appPermissionInfoList.length-index}}
                        </td>
                    </template>
                    </CDataTable>   
                </CCardBody>
            </CCard>
            <CCard v-if="adminInfo.groupNm">
                <CCardHeader>
                    <strong> 메뉴 접근 권한 </strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>                        
                        <CCol lg="12" style="margin-bottom:3px">
                            <span v-if="permissionGroupInfo.allMenuPermissionYn=='1'">모든 메뉴 허용</span>
                        </CCol>
                    </CRow>
                    <CRow v-if="permissionGroupInfo.allMenuPermissionYn=='0'">
                        <CCol sm="4" v-for="(item, index) in menuInfo" :key="index">
                            <CRow form class="form-group" >
                                <CCol tag="label" sm="6" class="col-form-label font-weight-bold">
                                    {{item.menuNm}}
                                </CCol>
                            </CRow>
                            <CRow form class="form-group" v-for="(subItem, subIndex) in item.childrenItems" :key="subIndex">
                                <CCol sm="5" v-if="subItem.menuKey!=102">
                                    {{subItem.menuNm}}
                                </CCol>
                                <CInputCheckbox
                                    :id="subItem.menuKey"
                                    label="Read"
                                    :inline="true"
                                    :checked="subItem.readYn==1"
                                    value="readYn"
                                    @change="checkedMenu($event)"
                                    v-if="subItem.menuKey!=102"
                                    disabled=true
                                    :custom="true"
                                    />
                                <CInputCheckbox
                                    :id="subItem.menuKey"
                                    label="Write"
                                    :checked="subItem.writeYn==1"
                                    value="writeYn"
                                    @change="checkedMenu($event)"
                                    v-if="item.menuKey!=101"
                                    disabled=true
                                    :custom="true"
                                    />
                                
                            </CRow>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
  </div>
</template>
<script>
import AppPermissionModal from '../permissionGroup/AppPermissionModal'
import PermissionGroupModal from '../permissionGroup/PermissionGroupModal'
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers, numeric, maxLength  } from "vuelidate/lib/validators"
import RSAKey from '@/js/rsa/rsa.js'
export default {
    name: 'RegistAccount',
    components:{
        AppPermissionModal,
        PermissionGroupModal 
    },
    mixins: [validationMixin],
    data () {
        return {
            isOpenPermissionGroupModal: false,
            isOpenAppPermissionModal: false,
            adminInfo:{
                adminKey:'',
                adminId:'',
                adminNm:'',
                pswd:'',
                phoneNumber:'',
                email:'',
                note:'',
                ipInfoList: [
                    {
                        startIp:''
                    },
                ],
                groupNm:'',
                groupKey:'',
            },
            fields:[],
            items:[],
            
            // 그리드 컬럼 설정
            appFields:[
                { key: 'no', label:'No', _style: { width: '1%'} },
                { key: 'appNm', label:'앱 이름', _style: { width: '20%'} },
                { key: 'packageNm', label:'패키지명', _style: { width: '20%'} },
                { key: 'appVersion', label:'버전',  _style: { width: '20%'} },
                { key: 'osType', label: 'OS', _style: { width: '20%'}, iconYn: true }
            ],
            permissionGroupInfo:{},
            menuInfo: [],            
            afterClick: false,
            pubRsaKey:[]
        }
    }, 
    props: {
        caption: {
            type: String,
            default: '계정관리'
        },
        hover: Boolean,
        striped: Boolean,
        border: Boolean,
        small: Boolean,
        fixed: Boolean,
        dark: Boolean,

    },
    computed: {
    },
    created () {
    },
    mounted() {
        this.$v.adminInfo.$touch()
        this.getRsaKey()
    },
    validations: {
        adminInfo: {
            adminId: {
                required,
            },
            adminNm: {
                required,
            },
            email: {
                email
            },
            pswd: {
                required,
                //minLength: minLength(8),
                strongPass: helpers.regex('strongPass', /^.*(?=^.{9,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!,@,#,$,%,^,&,*]).*$/)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs("pswd")
            },
            phoneNumber: {
                numeric,                
            },
            ipInfoList: {     
                $each: {
                    startIp:{
                       ipRegex: helpers.regex('ipRegex',/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/)
                    }
                }                
            },
            note:{
                minLength: minLength(0),
                maxLength : maxLength(200)
            },
            groupNm: {
                required,
            },
            
        }
    },
    methods: {
        addIpInput(){
            this.adminInfo.ipInfoList.push({startIp: ''})
        },
        removeIpInput(){
            this.adminInfo.ipInfoList.pop({startIp: ''})
        },
        async formSubmit() {
            this.afterClick = true
            
            if(this.validate()){       
                this.loading = true  // 로딩바 활성화   
                                
                const rsa = new RSAKey() 
                rsa.setPublic(this.pubRsaKey.publicKeyModulus, this.pubRsaKey.publicKeyExponent)
                let payload ={
                    adminId: this.adminInfo.adminId,
                    adminType: '1',
                    adminNm : this.adminInfo.adminNm,
                    pswd : rsa.encrypt(this.adminInfo.pswd),
                    phoneNumber : this.adminInfo.phoneNumber,
                    email : this.adminInfo.email,
                    note : this.adminInfo.note,
                    groupKey : this.adminInfo.groupKey,
                    groupNm : this.adminInfo.groupNm,
                    ipInfoList : this.adminInfo.ipInfoList
                }

                try {
                    await this.$store.dispatch('adminInfo/registerAdminInfo', payload,{
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    this.adminKey= this.$store.state.adminInfo.adminInfo.adminKey
                    alert("저장하였습니다.")
                    this.$router.go(-1)
                } catch(error){
                    this.$response(error)
                    this.afterClick = false
                    this.loading = false  // 로딩바 비활성화
                    this.getRsaKey() //실패시 재호출
                }
            }
            
            this.afterClick = false

        }, 
        goToList(){            
            this.$router.push({name: 'AccountList'})
        },
        
        addPermissionItem(data){
            if(data){
                let permissionGroupInfo = data[0]
                
                // 권한 상세조회
                if(!permissionGroupInfo.allAppChecked || !permissionGroupInfo.allMenuChecked){
                    this.searchInfo(permissionGroupInfo.groupKey)
                }
            } 
            this.isOpenPermissionGroupModal = false
        },
        
        async searchInfo(groupKey){
            try{
                let response = await this.$store.dispatch('permissionGroup/getPermissionGroupInfo', groupKey)
                    if(response.status == 200){
                        this.permissionGroupInfo = this.$store.state.permissionGroup.permissionGroupInfo;
                        this.adminInfo.groupNm = this.permissionGroupInfo.groupNm
                        this.adminInfo.groupKey = this.permissionGroupInfo.groupKey
                        this.menuInfo = this.$store.state.permissionGroup.menuPermissionInfo;
                    }
            }catch(error){
                console.log(error)
            }

        },
        validate(){
            if (!this.$v.adminInfo.adminId.required) {
                alert("아이디를 입력해주세요.")
                return false
            }
            else if (!this.$v.adminInfo.adminNm.required) {
                alert("이름을 입력해주세요.")
                return false
            }
            else if (!this.$v.adminInfo.pswd.required) {
                alert("비밀번호를 입력해주세요.")
                return false
            }
            else if (!this.$v.adminInfo.pswd.strongPass) {
                alert("비밀번호 규칙에 맞게 입력해주세요.")
                return false
            }
            else if (!this.$v.adminInfo.confirmPassword.sameAsPassword) {
                alert("비밀번호 확인을 정확하게 입력해주세요.")
                return false
            }
            else if (!this.$v.adminInfo.phoneNumber.numeric) {
                alert("전화번호 형식이 유효하지 않습니다.")
                return false
            }
            else if (!this.$v.adminInfo.email.email) {
                alert("이메일 형식이 유효하지 않습니다.")
                return false
            }
            else if (this.$v.adminInfo.ipInfoList.$error) {
                alert("IP 형식이 유효하지 않습니다.")
                return false
            }
            else if(!this.$v.adminInfo.groupNm.required){
                alert("권한 그룹을 선택해주세요.")
                return false
            }
            return true
        },
        async getRsaKey(){
            try{
                await this.$store.dispatch("loginStore/getRsaPubKey")
                this.pubRsaKey = this.$store.state.loginStore.pubKey
            }catch(error){
                console.log(error)
            }
        }

    }
}


</script>
